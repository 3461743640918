/*****************************************************************************************
 * 설명 : 결제
 * URI : /payment
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { Button, Dialog, FormControl, FormControlLabel, InputAdornment, Radio, RadioGroup } from '@mui/material';
import { InputEx } from 'components/inputEx';
import { useFormik } from 'formik';
import moment from 'moment';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { NavLink } from "react-router-dom";

import { showDialog } from 'reducers/dialogReducer';
import { setUserInfo } from 'reducers/userReducer';

import { Restful } from 'service/restful';
import { comma, PaperComponent } from 'service/utils';

import alertMsg from 'components/message';
import { baseURL, MESSAGE_DELAY } from 'config/config';
import LayoutSub from 'pages/homepage/layout/layoutSub';
import SafeModal from './safeModal';

import KAKAO_PAY_BTN from 'assets/images/payment_icon_yellow_small.png';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Payment = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [queryString, setQueryString] = useState({});
  const [serviceInfo, setServiceInfo] = useState({});

  const user = useSelector(state => state?.userInfo?.user);
  const orderNumber = `order_${new Date().getTime()}`;

  const [openModal, setOpenModal] = useState({open: false, modal: 'safe', data: []});

  const formik = useFormik({
    initialValues: {
      payMethod: 'card',
      paySubscriptionPlanAmt: 0,
      totalAmt: 0
    },
    onSubmit: (values) => {
      // setSelectedGiftcard(event.target.value);
      if( parseInt(values.totalAmt) > 0 ) {
        handlePayment();

      } else {
        // 무료결제가 아니고 정액 상품권으로 구매시 결제 금액 체크
        if( parseInt(serviceInfo?.price) > 0 ) {
          if( parseInt(values.totalAmt) < 1 && parseInt(user?.subscriptionRemainderAmt) < parseInt(serviceInfo?.price) ) {
            dispatch(showDialog({header: '안내', message: '정액상품 보유액이 부족합니다.'}));
            return;
          }
        }

        setPaymentDataFree();
      }
    }
  });

  /***************************************************************************************
   * 설명 : 서비스 정보 가져오기
  ***************************************************************************************/
  const getServiceInfo = () => {
    let params = {
      program: 'home',
      service: 'services',
      action: 'getServiceInfo',
      version: '1.0',
      serviceSeq: queryString.serviceSeq
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setServiceInfo(response.data.data[0]);
      } else {
        setServiceInfo({});

        dispatch(showDialog({header: '에러', message: '서비스 정보를 가져오는데 실패하였습니다.', click: () => navigate('/')}));
      }
    })
  }

  /***************************************************************************************
   * 설명 : 결제 취소 함수
  ***************************************************************************************/
  const cancelPayment = (pg_code) => {
    try {
      let params = {
        program: 'home',
        service: 'payment',
        action: 'cancelPayment',
        version: '1.0',
        impUid: pg_code,
      }

      getApi("get", params).then( (response) => {
        if( response !== undefined && response.data.result ) {
          dispatch(showDialog({header: '에러', message: '결제에 실패하여 결제가 취소되었습니다.'}));

        } else {
          dispatch(showDialog({header: '에러', message: '결제를 취소하는데 실패하였습니다. 고객센터로 문의해 주세요.'}));
        }
      });

    } catch (error) {
      dispatch(showDialog({header: '에러', message: '결제를 취소하는데 실패하였습니다. 고객센터로 문의해 주세요.'}));
      console.error('Failed to cancel payment:', error);
    }
  };

  /***************************************************************************************
   * 설명 : 결제 정보 저장
  ***************************************************************************************/
  const setPaymentData = async (data, orderNumber) => {
    let params = {
      program: 'home',
      service: 'payment',
      action: 'setPaymentData',
      version: '1.0',
      memNo: user?.memNo,
      ...formik.values,
      ...serviceInfo,
      ...queryString,
      ...data
    }

    params.name = queryString.name;

    try {
      getApi("post", params).then( response => {
        if( response !== undefined && response.data.result ) {
          alertMsg(response.data.message, "S", MESSAGE_DELAY);

          // 회원 정액 금액 차감 처리 - 클라이언트 전용
          if( parseInt(formik.values.paySubscriptionPlanAmt) > 0 ) {
            let tmp = {...user};
            tmp.subscriptionRemainderAmt = parseInt(tmp.subscriptionRemainderAmt) - parseInt(formik.values.paySubscriptionPlanAmt);
            dispatch(setUserInfo(tmp));
          }

          // 결과 페이지로 이동
          let tmp = {...queryString};
          tmp.paySeq = response.data.data;
          tmp.price = parseInt(formik.values.totalAmt);
          tmp.orderNumber = orderNumber;
          tmp.redirectUrl = 0;

          let uri = "?" + new URLSearchParams(tmp).toString();

          // navigate('/service/result' + uri);
          navigate('/service/payment/google' + uri);

        } else {
          // 결제가 실패했으므로 결제 취소 처리
          cancelPayment(data.imp_uid);
        }
      })
    } catch (error) {
      // 결제가 실패했으므로 결제 취소 처리
      cancelPayment(data.imp_uid);
    }
  }

  /***************************************************************************************
   * 설명 : 정액권 결제로 무료인 경우 처리
  ***************************************************************************************/
  const setPaymentDataFree = () => {
    if( formik.values.isAgree !== true ) {
      dispatch(showDialog({header: '안내', message: '개인정보 처리방침에 동의해 주시기 바랍니다.'}));
      return;
    }

    let orderNumber = `order_${new Date().getTime()}`;

    let params = {
      program: 'home',
      service: 'payment',
      action: 'setPaymentDataFree',
      version: '1.0',
      memNo: user?.memNo,
      ...serviceInfo,
      ...queryString,
      ...formik.values,
      buyer_email: user?.email,
      buyer_name: user?.userName,
      orderNumber: orderNumber
    }

    params.name = queryString.name;

    getApi("post", params).then( response => {
      if( response !== undefined && response.data.result ) {
        // 회원 정액 금액 차감 처리 - 클라이언트 전용
        let tmp = {...user};
        tmp.subscriptionRemainderAmt = parseInt(tmp.subscriptionRemainderAmt) - parseInt(formik.values.paySubscriptionPlanAmt);
        dispatch(setUserInfo(tmp));

        // 결과 페이지로 이동
        let tmp1 = {...queryString};
        tmp1.paySeq = response.data.data;

        let uri = "?" + new URLSearchParams(tmp1).toString();
        dispatch(showDialog({header: '안내', message: response?.data?.message, click: navigate('/service/result' + uri)}));

      } else {
        dispatch(showDialog({header: '에러', message: response?.data?.message || '서버와의 통신에 실패하였습니다.'}));
      }
    });
  }

  /***************************************************************************************
   * 설명 : 결제 처리 함수
  ***************************************************************************************/
  const handlePayment = () => {
    if( formik.values.payMethod === '' ) {
      dispatch(showDialog({header: '안내', message: '결제 방식을 선택하시기 바랍니다.'}));
      return;
    }

    if( formik.values.isAgree !== true ) {
      dispatch(showDialog({header: '안내', message: '개인정보 처리방침에 동의해 주시기 바랍니다.'}));
      return;
    }

    const { IMP } = window;  // 아임포트 객체 가져오기
    IMP.init('imp75070951'); // 아임포트 관리자 콘솔에서 확인한 가맹점 식별코드 입력
    // 신규 Mqu7v9ow9Zqhs46e17J8Ldc3wQG4AwXgIU7A6nPlNncGvP87wgodlq1Voa2LS3gRJxrt0C5iAIy1e4BP
    // 기존 8wjOPMX1zHc4SUBeYe7ba5OTDSEFObQXH9LxVJyvrLAjGbyEqGQBMEvpw95llVi354A1N9weV7yBj7gK
    // 신규 YCS32EL2Uh3iRDgzh1LGjhZ1NL8KpSHDI5dLvIGJccxZBL1bXkvSSYYB8eSzeDLz4BA5yxVW9wY9eHb1
    // 상점 아이디 : store-9fbf1c67-d188-4873-b82e-1347b653d910
    // PG상점 아이디 : G010034846
    // 페이팔 : sajuin123!#%

    const data = {
      pg: 'html5_inicis',
      pay_method: formik.values.payMethod,
      merchant_uid: orderNumber,  // 주문번호
      name: `${serviceInfo?.serviceName}`,
      amount: parseInt(formik.values.totalAmt),
      buyer_email: user?.email,
      buyer_name: user?.userName === '' ? queryString?.name : user?.userName,
      buyer_tel: '',
      buyer_addr: '',
      buyer_postcode: ''
    };

    switch(formik.values.payMethod) {
      case 'phone':
        data.pg = 'danal';
        data.pay_method = 'phone';
        data.phone = user?.phone; // 휴대폰 결제용 파라미터
        break;

      case 'trans':
        data.pg = 'danal_tpay';
        data.pay_method = 'trans';
        data.buyer_tel = user?.phone; // 실시간 계좌이체용 파라미터
        break;

      case  'kakaopay':
        data.pg = 'kakaopay.CAPJLGRXWE';
        data.pay_method = 'kakaopay';

        let tmp = {
          ...formik.values,
          ...serviceInfo,
          ...queryString,
          orderNumber: orderNumber,
          redirectUrl: 0
        }

        let uri = "?" + new URLSearchParams(tmp).toString();

        data.m_redirect_url = baseURL + '/payment/kakao' + uri;
        break;

      case 'naverpay':
        data.pg = 'naverpay.np_xeuwp647201';
        data.pay_method = 'naverpay';
        data.naverUseCfm = true; // 네이버페이 사용을 명시
        data.naverChainId = 'czBYbmJyRE51eml';
        data.naverPopupMode = true;
        data.naverProducts = [
          {
            categoryType: 'ETC',
            categoryId: queryString?.serviceSeq,
            uid: queryString?.serviceSeq,
            name: serviceInfo?.serviceName,
            count: 1,
            startDate: moment().format('YYYYMMDD'),
            endDate: moment().add(1, 'year').format('YYYYMMDD'),
          }
        ]
        break;

      case 'paypal':
        data.pg = 'paypal';
        data.pay_method = 'paypal';
        break;

      default:
        data.pg = 'html5_inicis'; // 기본 PG사 설정
        break;
    }

    IMP.request_pay(data, async (response) => {

      if (response.success) {

        // 로그인 풀림 시 결제 취소 처리
        if( ( user?.memNo ?? '' ) === '' ) {
          let params = {
            program: 'home',
            service: 'payment',
            action: 'cancelPayment',
            version: '1.0',
            impUid: response?.imp_uid,
          }

          getApi("get", params).then( (response) => {
            if( response !== undefined && response.data.result ) {
              // 결제 취소
              dispatch(showDialog({header: '에러', message: '로그인 상태가 아니어서 결제를 취소했습니다. 결제가 취소되지 않은 경우 고객센터로 문의해 주시기 바랍니다.', click: () => navigate('/login')}));

            } else {
              // 결제 취소
              dispatch(showDialog({header: '에러', message: '로그인 상태가 아니어서 결제를 취소했으나 오류가 발생하였습니다. 고객센터로 문의해서 환불 처리를 진행해 주시기 바랍니다.'}));
            }
          });

        // 결제 정보 저장 처리
        } else {
          await setPaymentData(response, orderNumber);
        }

      } else {
        if( ['naverpay', 'kakaopay'].includes(formik.values.payMethod) ) {
          dispatch(showDialog({header: '에러', message: `${response.error_msg}`}));
        } else {
          dispatch(showDialog({header: '에러', message: `결제에 실패하였습니다. 에러 내용: ${response.error_msg}`}));
        }
      }
    });
  }

  /***************************************************************************************
   * 설명 : 요금 계산
  ***************************************************************************************/
  const calc = () => {
    // 정액 상품권 보유액이 0보다 크면
    if( parseInt(user?.subscriptionRemainderAmt) > 0 ) {

      // 정액 상품권 보유액이 판매금액보다 클 경우
      if( parseInt(user?.subscriptionRemainderAmt) > serviceInfo.price ) {
        formik.setFieldValue('paySubscriptionPlanAmt', serviceInfo.price);
        formik.setFieldValue('totalAmt', 0);

      // 작을 경우 dkfla0318
      } else {
        let totalAmt = parseInt(serviceInfo.price) - parseInt(user?.subscriptionRemainderAmt);
        formik.setFieldValue('paySubscriptionPlanAmt', user?.subscriptionRemainderAmt);
        formik.setFieldValue('totalAmt', totalAmt);
      }

    // 정액 상품권 보유액이 없을 경우
    } else {
      formik.setFieldValue('paySubscriptionPlanAmt', 0);
      formik.setFieldValue('totalAmt', serviceInfo.price);
    }
  }

  /***************************************************************************************
   * 설명 : 최대 정액 요금 계산
  ***************************************************************************************/
  const maxPayCheck = (inputValue) => {
    let userAmt = parseInt(user?.subscriptionRemainderAmt);
    let inputAmt = parseInt(String(inputValue)?.replace(/,/g, ''));

    if( serviceInfo.price < inputAmt ) {
      if( inputAmt > serviceInfo.price ) {
        let value = userAmt > serviceInfo.price ? serviceInfo.price : userAmt;
        let totalAmt = ( serviceInfo.price - value ) < 1 ? 0 : ( serviceInfo.price - value );
        formik.setFieldValue('paySubscriptionPlanAmt', value);
        formik.setFieldValue('totalAmt', totalAmt);
      }

    } else {
      let totalAmt = ( serviceInfo.price - inputAmt ) < 1 ? 0 : ( serviceInfo.price - inputAmt );
      formik.setFieldValue('paySubscriptionPlanAmt', inputAmt);
      formik.setFieldValue('totalAmt', totalAmt);
    }
  }

  /***************************************************************************************
   * 설명 : 요금 계산
  ***************************************************************************************/
  useEffect(() => {
    if( (serviceInfo?.serviceId ?? '') !== '' ) {
      calc();
/*
      const loadGtagScript = () => {
        // gtag.js 스크립트를 동적으로 로드
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=AW-862730987`;
        script.async = true;
        document.head.appendChild(script);

        script.onload = () => {
          // 스크립트 로드 후 dataLayer와 gtag 함수 초기화
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-862730987');

          // Google Analytics 전환 이벤트 전송
          gtag('event', 'conversion', {
            'send_to': 'AW-862730987/sLrXCJXUu_UYEOv1sJsD',
            'value': serviceInfo?.price ?? 0,
            'currency': 'KRW',
            'transaction_id': orderNumber ?? ''
          });
        };

        script.onerror = () => {
          console.error('Google Analytics 스크립트를 로드하는 데 실패했습니다.');
        };
      };

      loadGtagScript();
      */
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceInfo]);

  /***************************************************************************************
   * 설명 : URI로 넘어온 서비스 정보 가져오기
  ***************************************************************************************/
  useEffect(() => {
    if( ( queryString?.serviceSeq ?? '') !== '' ) {
      getServiceInfo();
    }

    if( (user?.memNo ?? '') === '' ) {
      dispatch(showDialog({header: '안내', message: '결제를 진행하기 위해서는 로그인이 필요합니다.', click: () => navigate('/login')}));
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString]);

  /***************************************************************************************
   * 설명 : 데이터 로딩
  ***************************************************************************************/
  useEffect(() => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setQueryString(query);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search, Location])

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <LayoutSub>
      <section className="services-page">
        <form onSubmit={formik.handleSubmit}>
          <section className="service-introduce">
            <h3 className="service-title"><span>결제하기</span></h3>
            <h3 className="service-title mobile-only"><span>결제하기</span></h3>

            <div className="info-txt">
              <p>
                서비스명:
                <span className="bold ml5">
                  { // 신년운세, 토정비결 년도 표시
                    ['ss_saju02', 'saju01'].includes(serviceInfo?.serviceId) &&
                    serviceInfo?.applyYear + ' '
                  }
                  {serviceInfo?.serviceName}
                </span>
              </p>
              <p>주문금액: <span className="bold ml5">{comma(serviceInfo?.price)}원</span></p>
            </div>
          </section>

          <div className="line"></div>

          <section>
            <div className="f16">정액상품권 보유액: <span className="cblue">{comma(user?.subscriptionRemainderAmt)}원</span></div>

            <div className="giftcard-use">
              <InputEx
                name="paySubscriptionPlanAmt"
                formik={formik}
                className={"inputbox"}
                variant="outlined"
                disabled={user?.subscriptionRemainderAmt <= 1}
                InputProps={{
                  startAdornment: <InputAdornment position="start">사용</InputAdornment>,
                  endAdornment: <InputAdornment position="end">원</InputAdornment>,
                }}
                onChange={(event) => {
                  maxPayCheck(event.target.value);
                }}
              />
              <Button
                className="btn1 ml10"
                style={{width: '120px', lineHeight: '120%'}}
                disabled={user?.subscriptionRemainderAmt <= 1}
                onClick={() => {
                  let tmp = parseInt(user?.subscriptionRemainderAmt) > parseInt(serviceInfo?.price) ? parseInt(serviceInfo?.price) : parseInt(user?.subscriptionRemainderAmt);
                  let tmp1 = parseInt(serviceInfo?.price) - tmp;

                  formik.setFieldValue('paySubscriptionPlanAmt', tmp);
                  formik.setFieldValue('totalAmt', tmp1);
                }}
              >전액사용</Button>

              <Button
                className="btn1 btn2 ml10"
                style={{width: '120px', lineHeight: '120%'}}
                disabled={user?.subscriptionRemainderAmt <= 1}
                onClick={() => {
                  formik.setFieldValue('paySubscriptionPlanAmt', 0);
                  formik.setFieldValue('totalAmt', serviceInfo?.price);
                }}
              >사용안함</Button>
            </div>

            <div className="info-box sm">
              <p>정액상품권으로 결제하시면 최대 15%의 할인된 금액으로 서비스를 이용하실 수 있습니다.</p>
            </div>

            <NavLink to="/giftcard" className="link-txt-green mt10">정액상품권 구매하러가기 &gt;&gt;</NavLink>
          </section>

          <div className="line"></div>

          <section className="f20">
            <div>총결제금액: <span className="cred bold">{comma(formik.values.totalAmt)}원</span></div>
          </section>

          <div className="line"></div>

          { parseInt(formik.values.totalAmt) > 0 &&
            <section className="radio-select">
              <div className="label">결제수단 선택</div>
              <FormControl className="radio">
                <RadioGroup
                  defaultValue={formik.values.payMethod}
                  name="payMethod"
                  color="success"
                  className="radio"
                  onChange={formik.handleChange}
                >
                  <FormControlLabel value={'card'} control={<Radio />} label="신용카드" />
                  {/*
                    user?.userId === 'sys1000' &&
                    <>
                      <FormControlLabel value={'naverpay'} control={<Radio />}
                        label={
                          <div className="flex-row flex-center">
                            <img src={NAVER_BTN} alt="네이버페이" style={{height: '25px'}} />
                            <div className="ml10">네이버페이</div>
                          </div>
                        }
                      />

                      <FormControlLabel value={'paypal'} control={<Radio />} label="페이팔" />

                    </>
                  */}
                  <FormControlLabel value={'kakaopay'} control={<Radio />}
                    label={
                      <div className="flex-row flex-center">
                        <img src={KAKAO_PAY_BTN} alt="카카오페이" style={{height: '25px'}} />
                        <div className="ml10">카카오페이</div>
                      </div>
                    }
                  />
                  <FormControlLabel value={'phone'} control={<Radio />} label="휴대폰 소액결제" />
                  <FormControlLabel value={'trans'} control={<Radio />} label="실시간 계좌이체" />
                </RadioGroup>
              </FormControl>
            </section>
          }
          <NavLink
            className="link-txt-green mt30"
            onClick={(event) => {
              event.preventDefault();
              setOpenModal({open: true, modal: 'safe', data: formik.values});
            }}
          >사주인의 결제가 안전한 이유 &gt;&gt;</NavLink>

          <div className="line"></div>

          <section >
            <div className="agree-div">
              <label className="check">
                <InputEx
                  type="checkbox"
                  name="isAgree"
                  formik={formik}
                  className="check1"
                />
                개인정보 처리방침에 동의함
                <san className="cred">(필수)</san>
              </label>
            </div>

            <NavLink to="/contents?seq=98" target="_blank" className="link-txt-green mt15 mb20">개인정보 처리방침 보기 &gt;&gt;</NavLink>

            {/*
            <div className="info-box">
              <h5>유의사항</h5>
              <p>결제완료 후에는 ‘새로고침' 또는 ‘뒤로' 버튼을 누르지 마세요. 본인의 실수로 인한 경우 환불되지 않습니다.</p>
            </div>
            */}
          </section>

          <section className="center mt40">
            <Button
              type="submit"
              variant="contained"
              className="hbasic-btn green"
            >결제하기</Button>
          </section>
        </form>
      </section>

      { openModal.open && openModal.modal === 'safe' &&
        <Dialog
          open={openModal.open}
          onClose={() => (event, reason) => {
            if(reason && reason === "backdropClick") return;
            setOpenModal({ open: false, modal: openModal.modal, data: openModal.data });
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here
                borderRadius: "12px"
              },
            },
          }}
        >
          <section className="home-modal">
            <SafeModal
              open={openModal.open}
              close={() => setOpenModal({ open: false, modal: openModal.modal, data: openModal.data })}
            />
          </section>
        </Dialog>
      }
    </LayoutSub>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Payment;